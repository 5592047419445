
import { mapState } from 'vuex';

export default {
  name: 'Inspection',
  computed: {
    ...mapState('inspections__new', [
      'getInspectionsResult',
    ]),
  },
  watch: {
    '$route.params.inspection_id': {
      handler(newVal) {
        if (!newVal) {
          return;
        }
        this.$store.commit('cesium/reset');
        const inspectionFromList = this.getInspectionsResult?.find(x => x.id === newVal);
        if (inspectionFromList) {
          this.$store.state.inspections__new.currentInspection = inspectionFromList;
        } else if (this.$store.state.inspections__new.getInspectionsItemResult &&
          this.$store.state.inspections__new.getInspectionsItemResult.id === newVal) {
          this.$store.state.inspections__new.currentInspection = this.$store.state.inspections__new.getInspectionsItemResult;
        } else {
          this.$store.dispatch('inspections__new/getInspectionsItem', {
            id: newVal,
            onSuccess: newData => this.$store.state.inspections__new.currentInspection = newData,
          });
        }
      },
      deep: true,
      immediate: true,
    }
  },
}
